import React from "react";

const Principle = () => {
  return (
    <>
      <div className="principal">
        <div className="principal-row">
          <div className="principal-col">
            <img src="./img/pr.jpeg" />
            <h5>Mr.</h5>
          </div>
          <div className="principal-col">
            <h2>Principal Message</h2>
            <h6>
              Warm Greeting from Education Valley School Bhopal family, we
              firmly believed that school education lays the foundation for the
              future of the students and ensure a stable, successful and
              satisfied life. According to the words of Swami Vivekanand "Arise,
              awake and stop not till the goal is reached" echo in my mind. The
              School activities are planned and prepared meticulously, giving
              equal importance to academics, co-curricular and extra curricular
              activities. These activities strategically implemented to ensure
              the overall development of the students. Our mission is to produce
              educated smart and confident citizens of India,who can bring to
              the nation and make us proud.
            </h6>
            <h6>
              As Education Valley School Bhopal principal, I am more than
              willing to do all it takes to make our school an outstanding
              institution, where our students are nurtured through mind, body,
              and soul. Such a mission will not be possible to accomplish
              without the full support and cooperation of our parent body and
              community at large. Hence, should you have any suggestions or
              constructive feedback, do not hesitate to contact me by email or
              phone.
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default Principle;
