import React from "react";
import Banner from "./Banner";
import ReactCardSlider from "./ReactCardSlider";

// import ReactCardSlider from 'react-card-slider-component';
// import { Carousel } from 'react-carousel-minimal';
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const Home = () => {
  const handleDragStart = (e) => e.preventDefault();
  const sliderClick = (slider) => {};

  // const slides = [
  //   {
  //     image: "./images/cr1.jpg",
  //     title: "Merry Christmas",
  //     description: "25 December",
  //     clickEvent: sliderClick,
  //   },
  //   {
  //     image: "/images/new1.jpg",
  //     title: "Happy New Year",
  //     description: "01 January ",
  //     clickEvent: sliderClick,
  //   },

  //   {
  //     image: "./images/mk.jpg",
  //     title: "Happy Makar sankranti",
  //     description: "14 January ",
  //     clickEvent: sliderClick,
  //   },

  //   {
  //     image: "./images/rep.jpg",
  //     title: "Republic Day",
  //     description: "26 January",
  //     clickEvent: sliderClick,
  //   },
  //   {
  //     image: "./img/yoga.jpeg",
  //     title: "Happy Mahashivratri",
  //     description: "18 February",
  //     clickEvent: sliderClick,
  //   },
  //   {
  //     image: "./images/fa.jpeg",
  //     title: "Happy Holi",
  //     description: "8 March",
  //     clickEvent: sliderClick,
  //   },

  //   {
  //     image: "./images/g33.jpeg",
  //     title: "Mahavir Jayanti",
  //     description: "4 April ",
  //     clickEvent: sliderClick,
  //   },

  //   {
  //     image: "./img/yoga.jpeg",
  //     title: "Celebrating Yoga Day",
  //     description: "21 june",
  //     clickEvent: sliderClick,
  //   },
  //   {
  //     image: "./img/cultural.jpeg",
  //     title: "Cultural Activity",
  //     description: "yearly",
  //     clickEvent: sliderClick,
  //   },
  // ];

  const items = [
    <div style={{ marginRight: "5px" }}>
      <img
        src="./img1/g12.jpg"
       
        className="yours-custom-class"
        style={{ height: "12.5rem", width: "100%" }}
      />
    </div>,
    <div style={{ marginRight: "5px" }}>
      <img
        src="./img1/g1.jpg"
        
        className="yours-custom-class"
        style={{ height: "12.5rem", width: "100%" }}
      />
    </div>,
    <div style={{ marginRight: "5px" }}>
      <img
        src="./img1/g3.jpg"
        
        className="yours-custom-class"
        style={{ height: "12.5rem", width: "100%" }}
      />
    </div>,
    <div style={{ marginRight: "5px" }}>
      <img
        src="./img1/g4.jpg"
      
        className="yours-custom-class"
        style={{ height: "12.5rem", width: "100%" }}
      />
    </div>,
    <div style={{ marginRight: "5px" }}>
      <img
        src="./img1/g5.jpg"
        
        className="yours-custom-class"
        style={{ height: "12.5rem", width: "100%" }}
      />
    </div>,
    <div style={{ marginRight: "5px" }}>
      <img
        src="./img1/g6.jpg"
        
        className="yours-custom-class"
        style={{ height: "12.5rem", width: "100%" }}
      />
    </div>,
    <div style={{ marginRight: "5px" }}>
      <img
        src="./img1/g7.jpg"
        
        className="yours-custom-class"
        style={{ height: "12.5rem", width: "100%" }}
      />
    </div>,
    <div style={{ marginRight: "5px" }}>
      <img
        src="./img1/g8.jpg"
        
        class="yours-custom-class"
        style={{ height: "12.5rem", width: "100%" }}
      />
    </div>,
    <div style={{ marginRight: "5px" }}>
      <img
        src="./img1/g9.jpg"
        
        class="yours-custom-class"
        style={{ height: "12.5rem", width: "100%" }}
      />
    </div>,
    <div style={{ marginRight: "5px" }}>
      <img
        src="./img1/g10.jpg"
        
        class="yours-custom-class"
        style={{ height: "12.5rem", width: "100%" }}
      />
    </div>,
  ];

 
  const responsive = {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 5,
    },


};

  return (
    <>
      <Banner />
      <div className="new-div">
        <div className="content_box">
          <div className="first_box bxx">
            <div className="bx1">
              <p style={{textAlign:"center",fontSize:"30px",fontFamily:"sans-serif"}}>About</p>
              <h1>Education Valley SCHOOL </h1>
            </div>
            <div className="image_boxAb img-school">
              <img src="./img1/school.jpg" alt="" />
            </div>

            <div className="para-content">
              <p>
                Education Valley SCHOOL BHOPAL is based in city of lake
                Bhopal. Education Valley SCHOOL BHOPAL is recognized as a
                developmentally enriching learning space, making learning fun
                for children. 
                <Link to="/ourSchool">read more</Link>
              </p>
            </div>
          </div>
          <div className="second_box bxx">
            <div className="bx1">
              <p style={{textAlign:"center",fontSize:"30px",fontFamily:"sans-serif"}}>Message</p>
              <h1>Director</h1>
            </div>
            <div className="image_boxAb img-principle">
              <img src="./img1/pr.jpg" alt="" />
            </div>
            <div className="para-content">
              <p>
                Dear Parent & Students, It gives me great pleasure to welcome
                you to Education Valley SCHOOL BHOPAL. The school is
                committed to inculcating in all our students; strong ethical
                valued of integrity respect.
                <Link to="/director"> read more</Link>
              </p>
            </div>
          </div>
          <div className="second_box bxx">
            <div className="bx1">
              <p style={{textAlign:"center",fontSize:"30px",fontFamily:"sans-serif"}}>Message</p>
              <h1>Principal</h1>
            </div>
            <div className="image_boxAb img-principle">
              <img src="./img1/pri.jpg" alt="" />
            </div>
            <div className="para-content">
              <p>
                Welcome to Education Valley SCHOOL BHOPAL, a home built on
                decades of value imbued traditions. As educators, we understand
                that children learn more from what you are and what you do than
                what you say. <Link to="/principal">read more</Link>
              </p>
            </div>
          </div>
      
        </div>

        <div className="third_content_box">

          <div className="academic_planner">
            <div className="accontent">
              <p>Children Fair </p>
            </div>
            <div className="imgggbox1 imgggbox13">
              <div className="igg igg1">
                <img src="./img1/b.jpg" alt="" />
              </div>
            </div>
          </div>

          <div className="academic_planner">
            <div className="accontent">
              <p>Class Room</p>
              
            </div>

            <div className="imgggbox1 imgggbox13">
              <div className="igg igg1">
                <img src="./img1/ac.jpg" alt="" />
              </div>
            </div>
          </div>

          <div className="academic_planner">
            <div className="accontent">
              <p>Cultural activities</p>
            </div>

            <div className="imgggbox1 imgggbox13">
              <div className="igg igg1">
                <img src="./img1/c.jpg" alt="" />
              </div>
            </div>

          </div>

        </div>
        
        
        <div style={{backgroundColor:"#082246",marginTop:"100px"}}>

           <div style={{marginTop:"20px"}}>
          <h3 style={{textAlign:"center" ,color:"white",paddingTop:"10px"}}>
          Education Valley SCHOOL Gallery
          </h3>
           </div>

            <div style={{marginTop:"30px"}}>
          <AliceCarousel

                    items={items}
                    controlsStrategy="alternate"
                    responsive={responsive}
                    disableButtonsControls={true}
                    disableDotsControls={true}
                    />
              </div>   

               <div style={{textAlign:"center"}}>
               <Link to="/gallery">
                  <button style={{
              color: " #212529",
              backgroundColor: "#94d1f5",
              borderColor: "#94d1f5",
              borderRadius: "5px",
              paddingTop:"20px",
              marginTop:"50px",
              marginBottom:"20px"
            }}
            className="p-1">View More</button>
                </Link>
               </div>   
           
        </div>
      <div style={{backgroundColor:"#dad5fd",marginTop:"50px"}} >

      </div>
        
      </div>
    </>
  );
};

export default Home;


{/* <AliceCarousel      
                    items={items}
                    controlsStrategy="alternate"
                    responsive={responsive}
                    />
          <div className="fouthmain">
         
            <div className="fourthOne">
              <div className="ffone">
                <h3 style={{textAlign:"center"}}>Education Valley SCHOOL Gallery</h3>
              </div>
              
             
              <div className="ffbutton">
                <Link to="/gallery">
                  <button>View More</button>
                </Link>
              </div>
            
            </div>
            
          </div> */}



{/* <div className="ffslider">
<Carousel className="abcd">
  <Carousel.Item className="jhk">
    <img
      className="d-block"
      src="./img1/g12.jpg"
      alt="First slide"
    />
  </Carousel.Item>
  <Carousel.Item className="jhk">
    <img
      className="d-block"
      src="./img1/1p.jpg"
      alt="Second slide"
    />
  </Carousel.Item>

  <Carousel.Item className="jhk">
    <img
      className="d-block"
      src="./img1/g3.jpg"
      alt="Third slide"
    />
  </Carousel.Item>

  <Carousel.Item className="jhk">
    <img
      className="d-block"
      src="./img1/g6.jpg"
      alt="Third slide"
    />
  </Carousel.Item>
  <Carousel.Item className="jhk">
    <img
      className="d-block"
      src="./img1/g4.jpg"
      alt="Third slide"
    />
  </Carousel.Item>

  <Carousel.Item className="jhk">
    <img
      className="d-block"
      src="./img1/g6.jpg"
      alt="Third slide"
    />
  </Carousel.Item>

  <Carousel.Item className="jhk">
    <img
      className="d-block"
      src="./img1/g7.jpg"
      alt="Third slide"
    />
  </Carousel.Item>

  <Carousel.Item className="jhk">
    <img
      className="d-block"
      src="./img1/g8.jpg"
      alt="Third slide"
    />
  </Carousel.Item>

 
  <Carousel.Item className="jhk">
    <img
      className="d-block"
      src="./img1/g10.jpg"
      alt="Third slide"
    />
  </Carousel.Item>
</Carousel>
</div> */}