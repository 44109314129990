import React from "react";
import { FaInstagram,FaFacebook,FaTwitter} from 'react-icons/fa'

const Footer = () => {
  return (
    <>
      <div class="main-footer">
        <div className="footer-top">
          <div className="footerlogo-img">
            {/* <img src="./img/logo.png" alt="" /> */}
          </div>
          <div className="footer-content">
            <p>Have any questions?</p>
            <p>
              Call Us: 07552985025, Mail Us:
              
            </p>
            <p>
              Address - 163-D, firdous nagar, berasia road, bhopal , Bhopal, India, Madhya Pradesh
            </p>
          </div>
          <div className="socialmedia-link">
            <div className="socialall">
              <a
                href="https://www.facebook.com/educationvalley2707/about"
                target="_blank"
              >
                <div className="alllink-soc">
                  <FaFacebook />
                </div>
              </a>
              <a
                href="https://twitter.com/praveemishra07?t=Zyj73eSnYnhdrQZaj1t80A&s=08"
                target="_blank"
              >
                {" "}
                <div className="alllink-soc">
                  <FaTwitter />
                </div>
              </a>
              <a
                href="https://instagram.com/naveenvidyabhartiatlab?igshid=ZDdkNTZiNTM="
                target="_blank"
              >
                {" "}
                <div className="alllink-soc">
                  <FaInstagram />
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <p>© All Rights Reserved by, Education Valley School Bhopal</p>
          <p className="footer-made">
            Made with <i class=" ri-heart-3-fill"></i> by{" "}
            <a href="https://maitretech.com/"> Maitretech Solution</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
