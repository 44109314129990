import React from "react";

const TopNav = () => {
  let flag = 0;

  const openmenu = () => {
    let menubar = document.querySelector(".menu-bar");
    if (flag === 0) {
      menubar.style.display = "block";
      flag = 1;
    } else {
      menubar.style.display = "none";
      flag = 0;
    }
  };

  return (
    <>
      <div className="toptop">
        <div className="tonavbar1">
          {/* <img src="./img/logo.png" alt="" /> */}
          <div className="imp">
            <h1 style={{ color: "#ffff" }}>
              <span id="firsth">E</span>ducation <span id="firsth">V</span>alley{" "}
              <span id="firsth">S</span>CHOOL 
              <span id="firsth"></span> <span id="firsth">BHOPAL</span>
            </h1 >
            <p  style={{ color: "#ffff" }}>Learning today. Leading tomorrow. Inspired Forever.</p>
          </div>
        </div>
        <div className="menuimageicon">
          <i class="ri-menu-line" onClick={() => openmenu()}></i>
        </div>
      </div>
    </>
  );
};

export default TopNav;
